import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import { AddressManagerProvider } from './context/AddressManagerContext'

const customTheme = {
  button: {
    primary: {
       base: 'text-white bg-blue border border-transparent',
       active: 'active:bg-blue-600 hover:bg-blue-700 focus:ring focus:ring-blue-300',
       disabled: 'opacity-50 cursor-not-allowed',
     }
   }
};

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <SidebarProvider>
    {/* <AddressManagerProvider> */}
      <Suspense fallback={<ThemedSuspense />}>
        <Windmill usePreferences theme={customTheme}>
          <App />
        </Windmill>
      </Suspense>
    {/* </AddressManagerProvider> */}
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
