/* eslint-disable */

import React, { useState, useMemo, useEffect, useRef } from "react";
import { getScrtProvider } from '../apis/scrt';

const LOCAL_STORAGE_KEY = '___crypto-clerk-storage';
const SECRET_ADDRESS_LENGTH = 45;


function isAddressValid(address) {
  if (!address) return false;
  return address.indexOf("secret") !== -1
    && address.length === SECRET_ADDRESS_LENGTH;
}

const AVAILABLE_ADDRESS_COLORS = [
  "bg-red-300",
  "bg-green-300",
  "bg-yellow-300",
  "bg-orange-300",
  "bg-blue-300"
];

function getRandomColor() {
  const idx = Math.floor(Math.random() * AVAILABLE_ADDRESS_COLORS.length);
  return AVAILABLE_ADDRESS_COLORS[idx];
}

function getInitialState() {
  const initialState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (initialState == null) {
    return {};
  }
  return JSON.parse(initialState);
}

const initialState = getInitialState();

const initialWalletState = {
  address: '',
  iskeplr: false,
  color: '',
  selected: false,
  account: ''
};

export const AddressManagerContext = React.createContext();

export const AddressManagerProvider = ({ children }) => {
  const [wallets, setWallets] = useState(initialState);
  let provider = useRef(null);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(wallets));

    getScrtProvider().then(p => {
      provider = p;
    });
  }, [addWallet, getSelected, removeWallet, selectWallet]);

  async function selectWallet(address) {
    const newWallets = Object.assign({}, wallets);

    for (const it in newWallets) {
      newWallets[it].selected = false;
    }

    newWallets[address].selected = true;
    setWallets(newWallets);

    const account = await provider.signingClient.getAccount(address);
    localStorage.setItem("account", JSON.stringify(account));

    // Add secret as the default asset
    const currentAssets = localStorage.getItem("Assets");
    if (currentAssets) {
      const theAssets = JSON.parse(currentAssets);
      const walletAssets = theAssets.filter(a => a.address === address);

      if (walletAssets.length === 0) {
        
        // @StakeorDie: Handle the case in which an address has a 0 balance but still has history
        const balance = account.balance[0] === undefined ? 0 : account.balance[0].amount / 1e6

        var asset = {
          id: "secret",
          name: "Secret",
          symbol: "scrt",
          image:
          "https://assets.coingecko.com/coins/images/11871/large/Secret.png?1595520186",
          date_added: "",
          last_update: "",
          quantity: balance,
          current_price: 0,
          address
        };

        walletAssets.push(asset);

        localStorage.setItem("Assets", JSON.stringify(theAssets));
      }
    }

    window.location.reload();
  }

  function addWallet(form, account) {
    const { address, isKeplr, selected } = form;

    if (!isAddressValid(address) || wallets[address]) {
      throw new Error('Address was already added');
    }

    setWallets({
      ...wallets,
      [address]: {
        address,
        iskeplr: isKeplr || false,
        color: getRandomColor(),
        selected: selected || false,
        account
      },
    });
  }

  function removeWallet(address) {
    if (!wallets[address]) return;
    const newWallets = {
      ...wallets
    };
    delete newWallets[address];
    setWallets(newWallets);

    if (Object.keys(newWallets) <= 0) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.removeItem("account");
      localStorage.removeItem("Assets");
      window.location.reload();
    }
  }

  function getSelected() {
    for (const it in wallets) {
      if (wallets[it].selected) {
        return wallets[it];
      }
    }
    return initialWalletState;
  }

  const value = useMemo(
    () => ({
      wallets,
      selectWallet,
      addWallet,
      removeWallet,
      getSelected
    }),

    [wallets, addWallet, getSelected, removeWallet, selectWallet]
  );

  return (
    <AddressManagerContext.Provider value={value}>
    {children}
    </AddressManagerContext.Provider>
  );
};
