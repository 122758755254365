import axios from 'axios'
import moment from 'moment'
import { Decimal } from 'decimal.js'
import { SigningCosmWasmClient, CosmWasmClient } from "secretjs";

let provider = undefined;
let historical = undefined;

async function getHistoricalData() {
  if(!historical) {
    historical = "Run";
    const now = moment().unix();
    const secretUrl = 'https://api.coingecko.com/api/v3/coins/secret/market_chart/range?vs_currency=usd&from=1609459200&to=' + now;
    const sefiUrl = 'https://api.coingecko.com/api/v3/coins/sefi/market_chart/range?vs_currency=usd&from=1609459200&to=' + now
    //loop through pages untilc count = 0
    const secretRes = await axios.get(secretUrl)
    const secretPrices = secretRes.data.prices
    const sefiRes = await axios.get(sefiUrl)
    const sefiPrices = sefiRes.data.prices

    let secretDaysArray = []
    let secretPricesArray = []
    for(let i=0; i<secretPrices.length; i++) {
      secretDaysArray[i] = moment(secretPrices[i][0]).format("YYYY-MM-DD");
      secretPricesArray[i] = parseFloat(secretPrices[i][1].toFixed(2))
    }

    let sefiDaysArray = []
    let sefiPricesArray = []
    for(let i=0; i<sefiPrices.length; i++) {
      sefiDaysArray[i] = moment(sefiPrices[i][0]).format("YYYY-MM-DD")
      sefiPricesArray[i] = parseFloat(sefiPrices[i][1].toFixed(2))
    }

    return {
      secretPriceData: {
        Dates: secretDaysArray,
        PricePerDay: secretPricesArray
      },
      sefiPriceData: {
        Dates: sefiDaysArray,
        PricePerDay: sefiPricesArray
      },
      expirationTime: moment().endOf('day').unix()
    }
  }
}

export async function getScrtProvider() {

  if (!provider) {
    const SECRET_CHAIN_ID = process.env.REACT_APP_SECRET_CHAIN_ID;
    const SECRET_REST_API = process.env.REACT_APP_SECRET_REST_API;
    const SECRET_ARCHIVAL_REST_API = process.env.REACT_APP_SECRET_ARCHIVAL_REST_API;

    const sleep = (ms) => new Promise((accept) => setTimeout(accept, ms));

    let counter = 0;

    while (
      (!window.keplr && !window.getOfflineSigner && !window.getEnigmaUtils) ||
      counter > 100
    ) {
      await sleep(100);
      counter++;
    }

    if (counter >= 100) {
      alert("Please install keplr extension");
      return;
    }

    await window.keplr.enable(SECRET_CHAIN_ID);

    const offlineSigner = window.getOfflineSigner(SECRET_CHAIN_ID);
    const enigmaUtils = window.getEnigmaUtils(SECRET_CHAIN_ID);
    const accounts = await offlineSigner.getAccounts();
    const address = accounts[0].address;
    const client = new CosmWasmClient(SECRET_REST_API);
    const archivalClient = new CosmWasmClient(SECRET_ARCHIVAL_REST_API);
    const signingClient = new SigningCosmWasmClient(
      SECRET_REST_API,
      address,
      offlineSigner,
      enigmaUtils
    );

    let historicalPriceData = localStorage.getItem("historicalPriceData");
    if(historicalPriceData == null || historicalPriceData == undefined) {
      historicalPriceData = await getHistoricalData()
      localStorage.setItem("historicalPriceData", JSON.stringify(historicalPriceData))
    } else {
      try {
        historicalPriceData = JSON.parse(historicalPriceData)
      } catch(e) {
        historicalPriceData = await getHistoricalData()
        localStorage.setItem("historicalPriceData", JSON.stringify(historicalPriceData))
      }
      if(moment().unix() > historicalPriceData?.expirationTime ) {
        historicalPriceData = await getHistoricalData()
        localStorage.setItem("historicalPriceData", JSON.stringify(historicalPriceData))
      }
    }

    provider = {
      address,
      signingClient,
      client,
      archivalClient,
      keplr: window.keplr,
      chainId: SECRET_CHAIN_ID,
      historicalPriceData
    };
  }

  return provider;
}

export function coinConvert(number, decimals, type, fixed) {
  
  if (!number) return ''

  let theNumber = number
  if(typeof number === 'number') {
    theNumber = number.toString()
  }

  if ((theNumber).indexOf('.') === -1) {
    // In case `number` is an integer

    let result

    if (type && type === 'machine') {
      result = new Decimal(10).toPower(decimals).times(number)
    } else {
      result = new Decimal(number)
        .dividedBy(new Decimal(10).toPower(decimals))
    }

    if (typeof fixed !== 'undefined') {
      return result.toFixed(fixed)
    }

    return result.toString()

  } else {
    // In case is not an integer, we just handle it as float

    let result

    if (type && type === 'human') {
      result = new Decimal(number)
    } else {
      result = new Decimal(10).toPower(decimals).times(number)
    }

    if (typeof fixed !== 'undefined') {
      return result.toFixed(fixed)
    }

    return result.toString()
  }
}
